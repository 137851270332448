var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", {
        attrs: { title: "담당자 상세정보", "is-sticky": true },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "담당자 정보" } }),
          _c(
            "ul",
            { staticClass: "detail-list" },
            _vm._l(_vm.userInfo, function (info, index) {
              return _c(
                "detail-list-row",
                {
                  key: index,
                  attrs: {
                    info: info,
                    inputKey: info.key,
                    "input-value": _vm.user[info.key],
                    radioLabel: _vm.radioLabel[_vm.currentCompany],
                    maxlength: info.maxlength,
                  },
                  on: {
                    saveInputValue: _vm.onSaveInputValue,
                    togglePopup: _vm.togglePopup,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": info.isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_vm._v(" " + _vm._s(info.label) + " ")]
                  ),
                  _vm.user
                    ? [
                        ["createDT"].includes(info.key)
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("localDateYYYYMMDD")(
                                        _vm.user[info.key]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : ["latestAccessDT"].includes(info.key)
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("localDateYYYYMMDDTime")(
                                        _vm.user[info.key]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : ["role"].includes(info.key)
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("roleLabel")(_vm.user[info.key])
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [_vm._v(_vm._s(_vm.user[info.key]))]
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
          _c("subtitle", { attrs: { subtitle: "담당 설비 정보" } }),
          _c("linked-user-equip-list", {
            attrs: { userEqpsList: _vm.userEqpList },
          }),
          _c(
            "list-bottom-button-box",
            [
              _vm.isSupervisorOrFactory
                ? _c(
                    "button-basic",
                    {
                      staticClass: "mr-20",
                      attrs: {
                        slot: "button",
                        color: "gray-border-1",
                        size: "m",
                        width: 120,
                        disabled: _vm.isImpossibleDeleteUser,
                      },
                      on: { click: _vm.onShowDeleteUserPopup },
                      slot: "button",
                    },
                    [_vm._v(" 담당자 삭제 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
          isLoading: _vm.isLoadingSave,
        },
        on: { close: _vm.onCloseConfirmPopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }