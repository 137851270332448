<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header title="담당자 상세정보" :is-sticky="true" />
    <div class="container">
      <subtitle subtitle="담당자 정보" />
      <ul class="detail-list">
        <detail-list-row
          v-for="(info, index) in userInfo"
          :key="index"
          :info="info"
          :inputKey="info.key"
          :input-value="user[info.key]"
          @saveInputValue="onSaveInputValue"
          :radioLabel="radioLabel[currentCompany]"
          @togglePopup="togglePopup"
          :maxlength="info.maxlength"
        >
          <span slot="label" class="label" :class="{ 'label-required': info.isRequired }">
            {{ info.label }}
          </span>
          <template v-if="user">
            <span slot="contents" class="contents" v-if="['createDT'].includes(info.key)">
              {{ user[info.key] | localDateYYYYMMDD }}
            </span>
            <span slot="contents" class="contents" v-else-if="['latestAccessDT'].includes(info.key)">
              {{ user[info.key] | localDateYYYYMMDDTime }}
            </span>
            <span slot="contents" class="contents" v-else-if="['role'].includes(info.key)">
              {{ user[info.key] | roleLabel }}
            </span>
            <span slot="contents" class="contents" v-else>{{ user[info.key] }}</span>
          </template>
        </detail-list-row>
      </ul>
      <subtitle subtitle="담당 설비 정보" />
      <linked-user-equip-list :userEqpsList="userEqpList" />
      <list-bottom-button-box>
        <button-basic
          slot="button"
          color="gray-border-1"
          size="m"
          :width="120"
          @click="onShowDeleteUserPopup"
          v-if="isSupervisorOrFactory"
          :disabled="isImpossibleDeleteUser"
          class="mr-20"
        >
          담당자 삭제
        </button-basic>
      </list-bottom-button-box>
    </div>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onCloseConfirmPopup"
      @confirm="onConfirmInPopup"
      :isLoading="isLoadingSave"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import Subtitle from '@/component/subtitle/Subtitle';
import LinkedUserEquipList from './linkedUserEquipList/LinkedUserEquipList.vue';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import UserDetailInfo from './userDetailData/UserDetailInfo.json';
import RadioLabel from './userDetailData/RadioLabel.json';

export default {
  name: 'UserDetail',
  props: [],
  data() {
    return {
      user: '',
      userEqpList: null,
      showedPopupName: '',
      popupContentsKey: '',
      radioLabel: RadioLabel,
      userInfo: UserDetailInfo,
      isLoadingSave: false,
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isSupervisorOrFactory']),
    isImpossibleDeleteUser() {
      return this.myInfo.id === Number(this.$route.params.mId);
    },
  },
  created() {
    switch (this.currentCompany) {
      case 'M':
        this.getManufacturerUser(this.$route.params.mId);
        this.getManufacturerUserEqpList(this.$route.params.mId);
        break;
      case 'F':
        this.getFactoryUser(this.$route.params.mId);
        this.getFactoryUserEqpList(this.$route.params.mId);
        break;
    }
  },
  mounted() {},
  updated() {},
  watch: {},
  methods: {
    ...mapActions('factoryUsers', [
      'GET_FACTORY_USER',
      'GET_FACTORY_USER_EQPS',
      'PUT_FACTORY_USER',
      'DELETE_FACTORY_USER',
    ]),
    ...mapActions('manufacturerUsers', [
      'GET_MANUFACTURER_USER',
      'GET_MANUFACTURER_USER_EQPS',
      'PUT_MANUFACTURER_USER',
      'DELETE_MANUFACTURER_USER',
    ]),
    ...mapActions('users', ['GET_MY_INFO', 'GET_REISSUE_QR_CODE']),
    async getMyInfo() {
      await this.GET_MY_INFO();
    },
    async getFactoryUser(userId) {
      try {
        this.user = await this.GET_FACTORY_USER(userId);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getFactoryUserEqpList(userId) {
      try {
        this.userEqpList = await this.GET_FACTORY_USER_EQPS(userId);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async putFactoryUser(userId, user) {
      await this.PUT_FACTORY_USER([userId, user]);
    },
    async getManufacturerUser(userId) {
      try {
        this.user = await this.GET_MANUFACTURER_USER(userId);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getManufacturerUserEqpList(userId) {
      try {
        this.userEqpList = await this.GET_MANUFACTURER_USER_EQPS(userId);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async putManufacturerUser(userId, user) {
      await this.PUT_MANUFACTURER_USER([userId, user]);
    },
    async onSaveInputValue(inputKey, changedValue) {
      const userInfo = {
        userName: this.user.userName,
        phone: this.user.phone,
        role: this.user.role,
      };
      userInfo[inputKey] = changedValue;
      switch (this.currentCompany) {
        case 'M':
          await this.putManufacturerUser(this.user.id, userInfo);
          break;
        case 'F':
          await this.putFactoryUser(this.user.id, userInfo);
          break;
      }
      if (this.user.id === this.myInfo.id) {
        await this.getMyInfo();
      }
      this.user[inputKey] = changedValue;
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_USER_SUCCESS':
          this.togglePopup();
          this.$router.push(`/users`).catch((err) => err);
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_USER':
          this.onDeleteUser();
          break;
        case 'REISSUE_QR_CODE':
          this.onReissueQrCode();
          break;
        default:
          break;
      }
    },
    onShowDeleteUserPopup() {
      this.popupContentsKey = this.userEqpList.length > 0 ? 'IMPOSSIBLE_DELETE_USER' : 'DELETE_USER';
      this.togglePopup('ConfirmPopup', this.popupContentsKey);
    },
    async onDeleteUser() {
      try {
        switch (this.currentCompany) {
          case 'M':
            await this.DELETE_MANUFACTURER_USER(this.user.id);
            break;
          case 'F':
            await this.DELETE_FACTORY_USER(this.user.id);
            break;
        }
        this.togglePopup('ConfirmPopup', 'DELETE_USER_SUCCESS');
      } catch (error) {
        if (error.response.status === 400) {
          this.togglePopup('ConfirmPopup', 'IMPOSSIBLE_DELETE_USER');
        }
      }
    },
    async onReissueQrCode() {
      try {
        this.isLoadingSave = true;
        await this.GET_REISSUE_QR_CODE(this.user.loginId);
        this.togglePopup('ConfirmPopup', 'REISSUE_QR_CODE_SUCCESS');
      } catch (error) {
        this.togglePopup();
      } finally {
        this.isLoadingSave = false;
      }
    },
  },
  components: {
    Subtitle,
    DetailListRow,
    MainSectionHeader,
    LinkedUserEquipList,
    ListBottomButtonBox,
    ConfirmPopup,
  },
};
</script>

<style scoped lang="scss">
@import 'UserDetail';
</style>
