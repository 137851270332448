<template>
  <list :list="userEqpsList" :headerList="userEqpHeaderList">
    <li slot="list-body" v-for="(userEqp, index) in userEqpsList" :key="index">
      <span>{{ index + 1 }}</span>
      <span>
        <router-link :to="getEqpCodeLink(userEqp)">
          {{
            {
              type: isManufacturerComp ? 'M' : undefined,
              mEqpId: userEqp['mequipmentId'],
              eqpId: userEqp['id'],
            } | formatEQPCode
          }}
        </router-link>
      </span>
      <span>{{ userEqp.equipmentGroupName }}</span>
      <span>{{ userEqp.modelName }}</span>
      <span v-if="userEqp.equipmentName">{{ userEqp.equipmentName }}</span>
      <template v-if="userEqp.factoryEquipmentManagers">
        <span>
          <template v-if="userEqp.factoryEquipmentManagers.length === 1">
            {{ getManagerByPriority(userEqp.factoryEquipmentManagers, 1).userName }} / -
          </template>
          <template v-else>
            {{ getManagerByPriority(userEqp.factoryEquipmentManagers, 1).userName }} /
            {{ getManagerByPriority(userEqp.factoryEquipmentManagers, 2).userName }}
          </template>
        </span>
      </template>
      <span v-if="userEqp.equipmentManagers">
        <template v-for="(equipmentManager, index) in userEqp.equipmentManagers">
          <span :key="index">{{ equipmentManager.userName }}</span>
        </template>
      </span>
      <span v-if="userEqp.factoryCompanyName">{{ userEqp.factoryCompanyName }}</span>
    </li>
    <p slot="no-list-description">담당자로 지정된 설비가 없습니다.</p>
  </list>
</template>

<script>
import { mapGetters } from 'vuex';
import List from '@/component/list/List';
import ListHeaderData from './ListHeaderData.json';
import getManagerName from '@/asset/js/getManagerName';

export default {
  name: 'LinkedUserEquipList',
  props: ['userEqpsList'],
  data() {
    return {
      userEqpHeaderList: ListHeaderData,
    };
  },
  computed: {
    ...mapGetters('users', ['isFactoryComp', 'isManufacturerComp']),
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    getManagerByPriority(managers, priority) {
      return getManagerName(managers, priority);
    },
    getEqpCodeLink(equipment) {
      if (this.isFactoryComp && equipment.companyType === 'M') {
        return `/factory-equipments/${equipment.id}`;
      } else {
        return `/equipments/${equipment.id}`;
      }
    },
  },
  components: { List },
};
</script>

<style scoped lang="scss">
@import 'LinkedUserEquipList';
</style>
